<template>
  <div>
    <CRow>
      <CCol lg="4" md="12" sm="12">
        <CCard>
          <CCardHeader>
            <slot name="header">
              <CRow>
                <CCol class="col-12">
                  <CIcon name="cil-group"/>
                  Информация покупателя
                </CCol>
              </CRow>
            </slot>
          </CCardHeader>
          <CCardBody>
            <CTabs v-if="info !== null" variant="tabs" :active-tab="0">
              <CTab title="Общая">
                <ul class="list-group list-group-flush">
                  <li class="list-group-item">
                    <p class="text-muted">Телефон:</p>
                    <p class="mt-1">{{ info.phone | emptyValue }}</p>
                  </li>
                  <li class="list-group-item">
                    <p class="text-muted">E-Mail:</p>
                    <p class="mt-1">{{ info.email | emptyValue }}</p>
                  </li>
                  <li class="list-group-item">
                    <p class="text-muted">ФИО:</p>
                    <p class="mt-1">{{ name }}</p>
                  </li>
                  <li class="list-group-item">
                    <p class="text-muted">Пол:</p>
                    <p class="mt-1">{{ info.gender | gender }}</p>
                  </li>
                  <li class="list-group-item">
                    <p class="text-muted">День рождения:</p>
                    <p class="mt-1">{{ info.birthDate | date }}</p>
                  </li>
                </ul>
              </CTab>
              <CTab title="Адресс">
                <ul class="list-group list-group-flush">
                  <li class="list-group-item">
                    <p class="text-muted">Город:</p>
                    <p class="mt-1">{{ info.city | emptyValue }}</p>
                  </li>
                  <li class="list-group-item">
                    <p class="text-muted">Адресс:</p>
                    <p class="mt-1">{{ info.address | emptyValue }}</p>
                  </li>
                  <li class="list-group-item">
                    <p class="text-muted">Новая почта:</p>
                    <p class="mt-1">{{ info.newPostDepartment | emptyValue }}</p>
                  </li>
                </ul>
              </CTab>
              <CTab title="Карта">
                <ul class="list-group list-group-flush">
                  <li class="list-group-item">
                    <p class="text-muted">Номер карты:</p>
                    <p class="mt-1">{{ info.cardNumber | emptyValue }}</p>
                  </li>
                  <li class="list-group-item">
                    <p class="text-muted">Дата выдачи:</p>
                    <p class="mt-1">{{ info.cardIssueDate | emptyValue }}</p>
                  </li>
                  <li class="list-group-item">
                    <p class="text-muted">Место выдачи:</p>
                    <p class="mt-1">{{ info.cardIssuePoint | emptyValue }}</p>
                  </li>
                  <li class="list-group-item">
                    <p class="text-muted">Сумма начальная:</p>
                    <p class="mt-1">{{ info.startSum | emptyValue }}</p>
                  </li>
                  <li class="list-group-item">
                    <p class="text-muted">Сумма накопленая:</p>
                    <p class="mt-1">{{ info.accumulatedSum | emptyValue }}</p>
                  </li>
                  <li class="list-group-item">
                    <p class="text-muted">Процент скидки:</p>
                    <p class="mt-1">{{ info.discountPercent | emptyValue }}</p>
                  </li>
                </ul>
              </CTab>
            </CTabs>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol lg="8" md="12" sm="12">
        <CCard>
          <CCardHeader>
            <slot name="header">
              <CRow>
                <CCol class="col-12">
                  <CIcon name="cil-cart"/>
                  Покупки
                </CCol>
              </CRow>
            </slot>
          </CCardHeader>
          <CCardBody>
            <CTabs variant="tabs" :active-tab="0">
              <CTab title="Все">
                <CRow>
                  <CCol sm="4">
                    <CCallout color="info">
                      <span class="text-muted">Общая сумма покупок</span><br>
                      <strong class="h3">{{ moneyFormat(total.sum) }}</strong>
                    </CCallout>
                  </CCol>
                  <CCol sm="4">
                    <CCallout color="warning">
                      <span class="text-muted">Средний чек</span><br>
                      <strong class="h3">{{ moneyFormat(total.avg) }}</strong>
                    </CCallout>
                  </CCol>
                  <CCol sm="4">
                    <CCallout color="danger">
                      <span class="text-muted">Товары</span><br>
                      <strong class="h3">{{ total.products }}</strong>
                    </CCallout>
                  </CCol>
                  <CCol sm="4">
                    <CCallout color="success">
                      <span class="text-muted">Документы</span><br>
                      <strong class="h3">{{ total.documents }}</strong>
                    </CCallout>
                  </CCol>
                  <CCol sm="4">
                    <CCallout color="info">
                      <span class="text-muted">Заказы (без возвратов)</span><br>
                      <strong class="h3">{{ total.orders }}</strong>
                    </CCallout>
                  </CCol>
                  <CCol sm="4">
                    <CCallout color="warning">
                      <span class="text-muted">Возвраты</span><br>
                      <strong class="h3">{{ total.returns }}</strong>
                    </CCallout>
                  </CCol>
                </CRow>
                <CDataTable
                    v-if="purchases !== null"
                    :items="purchases"
                    :fields="purchasesFields"
                    hover
                    pagination
                    :tableFilter=false
                    sorter
                >
                  <template #show_sales="{item}">
                    <td class="py-2">
                      <CButton
                          color="primary"
                          variant="outline"
                          square
                          size="sm"
                          @click="toggleDetails(item)"
                      >
                        <CIcon :name="Boolean(item._toggled) ? 'cil-caret-top' : 'cil-caret-bottom'"/>
                      </CButton>
                    </td>
                  </template>
                  <template #details="{item}">
                    <CCollapse :show="Boolean(item._toggled)" :duration="collapseDuration">
                      <CCard>
                        <CCardBody>
                          <CDataTable :items="item.sales" :fields="salesFields" hover></CDataTable>
                        </CCardBody>
                      </CCard>
                    </CCollapse>
                  </template>
                </CDataTable>
              </CTab>
              <CTab title="Возвраты">
                <CRow>
                  <CCol sm="4">
                    <CCallout color="info">
                      <span class="text-muted">Кол-во возвратов</span><br>
                      <strong class="h3">{{ total.returns }}</strong>
                    </CCallout>
                  </CCol>
                  <CCol sm="4">
                    <CCallout color="warning">
                      <span class="text-muted">Общая сумма возвратов</span><br>
                      <strong class="h3">{{ moneyFormat(total.returnsSum) }}</strong>
                    </CCallout>
                  </CCol>
                  <CCol sm="4">
                    <CCallout color="danger">
                      <span class="text-muted">Процент возвратов (от суммы)</span><br>
                      <strong class="h3">{{ total.returnsPercent }}%</strong>
                    </CCallout>
                  </CCol>
                </CRow>
                <CDataTable
                    v-if="returns !== null"
                    :items="returns"
                    :fields="returnsFields"
                    hover
                    pagination
                    :tableFilter=false
                    sorter
                >
                </CDataTable>
              </CTab>
            </CTabs>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import moment from 'moment'
import customer from '@/api/customer'
import sale from '@/api/sale'

moment.locale('ru')

export default {
  name: 'Info',
  components: {},
  data() {
    return {
      info: null,
      purchases: null,
      total: {
        sum: 0,
        avg: 0,
        products: 0,
        orders: 0,
        documents: 0,
        returns: 0,
        returnsSum: 0,
        returnsPercent: 0
      },
      collapseDuration: 0,
      returns: null,
      sales: [],
      salesFields: [
        {
          key: 'sku',
          label: 'СКУ'
        },
        {
          key: 'sum',
          label: 'Сумма'
        },
        {
          key: 'title',
          label: 'Название'
        }
      ],
      showSalesModal: false,
      purchasesFields: [
        {
          key: 'number',
          label: '#',
          _style: 'width:1%',
          sorter: false,
          filter: false
        },
        {
          key: 'documentCode',
          label: 'Код док.'
        },
        {
          key: 'date',
          label: 'Дата'
        },
        {
          key: 'count',
          label: 'Кол-во позиций'
        },
        {
          key: 'shop',
          label: 'Магазин'
        },
        {
          key: 'sum',
          label: 'Сумма'
        },
        {
          key: 'show_sales',
          label: '',
          _style: 'width:1%',
          sorter: false,
          filter: false
        }
      ],
      returnsFields: [
        {
          key: 'sku',
          label: 'СКУ'
        },
        {
          key: 'documentCode',
          label: 'Код док.'
        },
        {
          key: 'date',
          label: 'Дата'
        },
        {
          key: 'shop',
          label: 'Магазин'
        },
        {
          key: 'sum',
          label: 'Сумма'
        }
      ]
    }
  },
  filters: {
    emptyValue(val) {
      if ((val === null) || (val === undefined) || (val.length === 0)) {
        return '(не задано)'
      }

      return val
    },
    date(val) {
      if ((val === null) || (val === undefined) || (val.length === 0)) {
        return '(не задано)'
      }

      return moment(val, 'YYYY-MM-DD').format('D MMMM, YYYY г.')
    },
    gender(val) {
      if (val === null) {
        return '(не задано)'
      } else if (Number(val) === 0) {
        return 'Мужской'
      } else if (Number(val) === 1) {
        return 'Женский'
      }

      return '(не задано)'
    }
  },
  methods: {
    moneyFormat(val) {
      return String(new Intl.NumberFormat('ru-RU').format(val))
    },
    groupBy(xs, key) {
      return xs.reduce(function(rv, x) {
        (rv[ x[ key ] ] = rv[ x[ key ] ] || []).push(x);
        return rv;
      }, {});
    },
    toggleDetails (item) {
      this.$set(this.purchases.find(p => p.documentCode === item.documentCode), '_toggled', !item._toggled)
      this.collapseDuration = 300
      this.$nextTick(() => { this.collapseDuration = 0})
    },
    parseSales(sales) {
      let grouped = this.groupBy(sales, 'documentCode')

      let r = []
      let number = 0

      let pureOrders = sales.reduce((res, obj) => {
        if (!(obj.returnDocumentCode in res)) {
          res[obj.returnDocumentCode] = 0
        }
        res[obj.returnDocumentCode] = res[obj.returnDocumentCode] + obj.count

        return res;
      }, {})

      for(const prop in pureOrders) {
        if (pureOrders[prop] > 0) {
          this.total.orders++
        }
      }

      this.returns = sales.filter(s => s.sum < 0)

      Object.keys(grouped)
            .forEach(documentCode => {
              let sales = grouped[ documentCode ]

              r.push({
                number: ++number,
                documentCode,
                date: sales[ 0 ].date,
                count: sales.length,
                shop: sales[ 0 ].shop,
                sum: sales.reduce((r, s) => r + s.sum, 0),
                sales
              })
            })

      this.total.documents = r.length
      this.total.sum = r.reduce((total, p) => total + p.sum, 0)
      this.total.avg = Math.round(this.total.sum / this.total.orders * 100) / 100
      this.total.products = r.reduce((total, p) => total + p.count, 0)

      this.total.returns = this.returns.length
      this.total.returnsSum = Math.abs(this.returns.reduce((total, ret) => total + ret.sum, 0))
      this.total.returnsPercent = Math.round(this.total.returnsSum / this.total.sum * 10000) / 100

      this.purchases = r
    }
  },
  computed: {
    name() {
      if (this.info !== null) {
        return `${this.info.firstName || ''} ${this.info.lastName || ''} ${this.info.middleName || ''}`
      }

      return 'Имя пользователя'
    }
  },
  created() {
    this.$store.commit('common/set', [
      'breadcrumbs',
      [
        {
          text: 'Главная',
          to: '/dashboard'
        },
        {
          text: 'Покупатели',
          to: '/customers'
        },
        {
          text: 'Информация покупателя',
        }
      ]
    ])
    customer.byForeignId(this.$route.params.id)
            .then(r => this.info = r.data)
    sale.allByCustomer(this.$route.params.id)
        .then(r => this.parseSales(r.data))
  }
}
</script>
